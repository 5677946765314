<div class="menu-sidebar" role="menubar">
  <ng-container *ngIf="group.includes('Learn')">
    <div
      class="sq-box"
      role="menuitem"
      tabindex="0"
      aria-label="Navigate to Learn"
      *crtxHasAnyAuthority="['ROLE_ADMIN', 'ROLE_TRAINER', 'ROLE_TRAINEE']"
    >
      <div>
        <a href="#/advisor-dashboard">
          <svg-icon [svgStyle]="{ 'width.%': 78 }" id="cortex-learn-icon" src="/assets/images/home.svg" title="Learn Page"> </svg-icon>
        </a>
      </div>
    </div>
    <div
      class="sq-box"
      role="menuitem"
      tabindex="0"
      *crtxHasAnyAuthority="['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_TRAINEE']"
      aria-label="Navigate to Trainee Dashboard"
      [ngClass]="{ 'selected-item': checkCurrentLink('track-progress') }"
    >
      <div>
        <a href="#/track-progress" (click)="sendTelemetry()">
          <svg-icon [svgStyle]="{ 'width.%': 78 }" id="trainee-dashboard-icon" src="/assets/images/dashboard.svg" title="Trainee Dashboard">
          </svg-icon>
        </a>
      </div>
    </div>

    <div
      class="sq-box"
      role="menuitem"
      tabindex="0"
      aria-label="Navigate to Browse Courses"
      [ngClass]="{ 'selected-item': checkCurrentLink('learn/courses') }"
      *crtxHasAnyAuthority="['ROLE_ADMIN', 'ROLE_TRAINER', 'ROLE_TRAINEE']"
    >
      <div>
        <a href="#/learn/courses">
          <svg-icon [svgStyle]="{ 'width.%': 78 }" id="cortex-learn-icon" src="/assets/images/education.svg" title="Browse Courses">
          </svg-icon>
        </a>
      </div>
    </div>

    <div
      class="sq-box"
      role="menuitem"
      tabindex="0"
      *crtxHasAnyAuthority="['ROLE_ADMIN', 'ROLE_TRAINER']"
      aria-label="Navigate to Training Bank"
      [ngClass]="{ 'selected-item': checkCurrentLink('training-bank') }"
    >
      <div>
        <a href="#/training-bank" (click)="sendTelemetry()">
          <svg-icon [svgStyle]="{ 'width.%': 78 }" id="training-bank-icon" src="/assets/images/training-bank.svg" title="Training Bank">
          </svg-icon>
        </a>
      </div>
    </div>

    <div
      class="sq-box"
      role="menuitem"
      tabindex="0"
      *crtxHasAnyAuthority="['ROLE_TRAINER']"
      aria-label="Navigate to Trainer Dashboard"
      [ngClass]="{ 'selected-item': checkCurrentLink('trainer-dashboard') }"
    >
      <div>
        <a href="#/trainer-dashboard" (click)="sendTelemetry()">
          <svg-icon
            [svgStyle]="{ 'width.%': 78 }"
            id="trainer-dashboard-icon"
            src="/assets/images/trainer-dashboard.svg"
            title="Trainer Dashboard"
          >
          </svg-icon>
        </a>
      </div>
    </div>
    <div
      class="sq-box"
      role="menuitem"
      tabindex="0"
      *crtxHasAnyAuthority="['ROLE_ADMIN', 'ROLE_AGENT']"
      aria-label="Navigate to Continuous Learning Framework"
      [ngClass]="{ 'selected-item': checkCurrentLink('continuous-learning-dashboard') }"
    >
      <div>
        <a href="#/continuous-learning-dashboard" (click)="sendTelemetry()">
          <svg-icon
            [svgStyle]="{ 'width.%': 78 }"
            id="continuous-learning-framework-icon"
            src="/assets/images/dashboard.svg"
            title="Continuous Learning Framework"
          >
          </svg-icon>
        </a>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="group.includes('Empower')">
    <div
      class="sq-box"
      (click)="deactivateAllIframeDesktops()"
      role="menuitem"
      tabindex="0"
      *crtxHasAnyAuthority="['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_SUPERVISOR']"
      aria-label="Navigate to Agent Desktop"
      [ngClass]="{ 'selected-item': checkCurrentLink('agent-desktop', 'reporting') }"
    >
      <div>
        <a href="#/agent-desktop" (click)="sendTelemetry()">
          <svg-icon
            [svgStyle]="{ 'width.%': 78 }"
            id="agent-desktop-icon"
            src="/assets/images/historical-data-processing.svg"
            title="Agent Desktop"
          >
          </svg-icon>
        </a>
      </div>
    </div>

    <ng-container *ngIf="menuConfig.reportingDashboard">
      <div
        class="sq-box"
        (click)="deactivateAllIframeDesktops()"
        role="menuitem"
        tabindex="0"
        *crtxHasAnyAuthority="['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_SUPERVISOR']"
        aria-label="Navigate to Agent Desktop"
        [ngClass]="{ 'selected-item': checkCurrentLink('reporting') }"
      >
        <div>
          <a href="#/agent-desktop/reporting/home/supervisors/realtime">
            <svg-icon [svgStyle]="{ 'width.%': 78 }" id="agent-dashboard-icon" src="/assets/images/dashboard.svg" title="Agent Dashboard">
            </svg-icon>
          </a>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="menuConfig.nudgeConfiguration">
      <div
        class="sq-box"
        role="menuitem"
        *crtxHasAnyAuthority="['ROLE_ADMIN', 'ROLE_AGENT']"
        tabindex="0"
        aria-label="Navigate to Nudge Configuration"
        [ngClass]="{ 'selected-item': checkCurrentLink('nudge') }"
      >
        <div>
          <a href="#/nudge">
            <svg-icon [svgStyle]="{ 'width.%': 78 }" id="nudge-config-icon" src="/assets/images/dialer.svg" title="Nudge Configuration">
            </svg-icon>
          </a>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="menuConfig.aiAsCustomer && softPhoneMode === 'ai-as-customer'">
      <div
        class="sq-box"
        (click)="deactivateAllIframeDesktops()"
        role="menuitem"
        *crtxHasAnyAuthority="['ROLE_ADMIN', 'ROLE_AGENT']"
        tabindex="0"
        aria-label="Navigate to Ai as Customer"
        [ngClass]="{ 'selected-item': checkCurrentLink('ai-as-customer') }"
      >
        <div>
          <a href="#/ai-as-customer">
            <svg-icon
              class="ai-as-customer-config-icon"
              [svgStyle]="{ 'width.%': 78 }"
              id="Aiascustomer"
              src="/assets/images/ai-as-customer.svg"
              title="Ai as Customer"
            >
            </svg-icon>
          </a>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="menuConfig.conversationDesigner">
      <div
        class="sq-box"
        (click)="deactivateAllIframeDesktops()"
        role="menuitem"
        tabindex="0"
        aria-label="Navigate to Conversation Designer"
        [ngClass]="{ 'selected-item': checkCurrentLink('cd') }"
        *crtxHasAnyAuthority="['ROLE_ADMIN']"
      >
        <a [href]="envUrl" target="_blank" rel="noopener noreferrer">
          <svg-icon
            [svgStyle]="{ 'width.%': 78 }"
            id="conversation-designer-icon"
            src="/assets/images/bot.svg"
            title="Conversation Designer"
          >
          </svg-icon>
        </a>
      </div>
    </ng-container>

    <ng-container *ngIf="iframeDesktopList && iframeDesktopList.length">
      <ng-container *crtxHasAnyAuthority="['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_SUPERVISOR']">
        <div
          *ngFor="let iframe of iframeDesktopList; let idx = index"
          class="sq-box"
          role="menuitem"
          tabindex="0"
          [aria-label]="'Navigate to ' + iframe.label"
          [ngClass]="{ 'selected-item': checkCurrentIframeDesktop(idx) }"
          [title]="iframe.label"
          [id]="'iframe-desktop-' + idx + '-menu'"
        >
          <div (click)="activateIframeDesktop(idx)">
            <svg-icon [svgStyle]="{ 'width.%': 78, fill: '#fff' }" [id]="'iframe-desktop-' + idx + '-icon'" [src]="iframe.icon"></svg-icon>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="group.includes('Optimize') && !checkUserAccessForOptimize()">
    <div
      class="sq-box"
      (click)="deactivateAllIframeDesktops()"
      role="menuitem"
      *crtxHasAnyAuthority="['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_SUPERVISOR']"
      tabindex="0"
      aria-label="Navigate to Knowledge Dashboard"
      [ngClass]="{ 'selected-item': checkCurrentLink('knowledge-dashboard') }"
    >
      <div>
        <a href="#/knowledge-dashboard">
          <svg-icon
            [svgStyle]="{ 'width.%': 78 }"
            id="knowledge-dashboard-icon"
            src="/assets/images/knowledge-dashboard.svg"
            title="Knowledge Dashboard"
          >
          </svg-icon>
        </a>
      </div>
    </div>

    <div
      class="sq-box"
      role="menuitem"
      (click)="deactivateAllIframeDesktops()"
      *crtxHasAnyAuthority="['ROLE_ADMIN', 'ROLE_SUPERVISOR']"
      tabindex="0"
      aria-label="Navigate to Dashboard"
      [ngClass]="{ 'selected-item': checkCurrentLink('supervisors') }"
    >
      <div>
        <a href="#/dashboard/home/supervisors/realtime">
          <svg-icon [svgStyle]="{ 'width.%': 78 }" id="dashboard-icon" src="/assets/images/dashboard.svg" title="Supervisors Dashboard">
          </svg-icon>
        </a>
      </div>
    </div>

    <div
      class="sq-box"
      role="menuitem"
      (click)="deactivateAllIframeDesktops()"
      *crtxHasAnyAuthority="['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_SUPERVISOR']"
      tabindex="0"
      aria-label="Navigate to Agent Dashboard"
      [ngClass]="{ 'selected-item': checkCurrentLink('agent-dashboard') }"
    >
      <div>
        <a href="#/dashboard/agent-dashboard">
          <svg-icon
            [svgStyle]="{ 'width.%': 78 }"
            id="agent-dashboard-icon"
            src="/assets/content/images/trainer-dashboard.svg"
            title="Agent Dashboard"
          >
          </svg-icon>
        </a>
      </div>
    </div>
    <div
      class="sq-box"
      role="menuitem"
      (click)="deactivateAllIframeDesktops()"
      tabindex="0"
      aria-label="Navigate to Conversational Analytics"
      *crtxHasAnyAuthority="['ROLE_ADMIN', 'ROLE_TRAINER', 'ROLE_SME', 'ROLE_SUPERVISOR']"
      [ngClass]="{ 'selected-item': checkCurrentLink('conversational-analytics') }"
    >
      <div>
        <a href="#/conversational-analytics">
          <svg-icon
            [svgStyle]="{ 'width.%': 78 }"
            id="conversational-analytics-icon"
            src="/assets/images/conversational-analytics.svg"
            title="Conversational Analytics"
          >
          </svg-icon>
        </a>
      </div>
    </div>
  </ng-container>

  <!-- below code needs modification as checkUserAccessForOptimize should take value from config
    This check for the custom user access and can show the icons accordingly -->
  <ng-container *ngIf="group.includes('Optimize')">
    <div
      class="sq-box"
      role="menuitem"
      (click)="deactivateAllIframeDesktops()"
      tabindex="0"
      aria-label="Navigate to Wealth Manager Dashboard"
      *crtxHasAnyAuthority="['ROLE_ADMIN', 'ROLE_TRAINER', 'ROLE_SME', 'ROLE_SUPERVISOR']"
      [ngClass]="{ 'selected-item': checkCurrentLink('conversational-analytics') }"
    >
      <div>
        <a href="#/wealth-manager-dashboard">
          <svg-icon
            [svgStyle]="{ 'width.%': 78 }"
            id="trainer-icon"
            src="/assets/images/message-square.svg"
            title="Wealth Manager Dashboard"
          >
          </svg-icon>
        </a>
      </div>
    </div>
  </ng-container>

  <div *crtxHasAnyAuthority="['ROLE_ADMIN']">
    <div
      class="sq-box"
      role="menuitem"
      (click)="deactivateAllIframeDesktops()"
      tabindex="0"
      aria-label="Navigate to Widget Viewer"
      [ngClass]="{ 'selected-item': checkCurrentLink('widgets') }"
      *ngIf="group.includes('Other') && !checkUserAccessForOptimize()"
    >
      <a href="#/widgets">
        <svg-icon [svgStyle]="{ 'width.%': 78 }" id="widget-viewer-icon" src="/assets/images/widget-viewer.svg" title="Widget Viewer">
        </svg-icon>
      </a>
    </div>
  </div>

  <ng-container *ngIf="group.includes('Experience')">
    <div
      class="sq-box"
      role="menuitem"
      (click)="deactivateAllIframeDesktops()"
      *crtxHasAnyAuthority="['ROLE_ADMIN', 'ROLE_AGENT']"
      tabindex="0"
      aria-label="Navigate to Experience Page"
      [ngClass]="{ 'selected-item': checkCurrentLink('experience') }"
    >
      <div>
        <a href="#/experience">
          <svg-icon [svgStyle]="{ 'width.%': 78 }" id="experience-icon" src="/assets/images/experience-sidebar.svg" title="Experience">
          </svg-icon>
        </a>
      </div>
    </div>
    <div *crtxHasAnyAuthority="['ROLE_ADMIN']">
      <div
        class="sq-box"
        role="menuitem"
        (click)="deactivateAllIframeDesktops()"
        tabindex="0"
        aria-label="Navigate to Conversation Designer"
        [ngClass]="{ 'selected-item': checkCurrentLink('cd') }"
      >
        <a [href]="envUrl" target="_blank" rel="noopener noreferrer">
          <svg-icon
            [svgStyle]="{ 'width.%': 78 }"
            id="conversation-designer-icon"
            src="/assets/images/bot.svg"
            title="Conversation Designer"
          >
          </svg-icon>
        </a>
      </div>
    </div>

    <div *crtxHasAnyAuthority="['ROLE_ADMIN']">
      <div
        class="sq-box"
        role="menuitem"
        (click)="deactivateAllIframeDesktops()"
        tabindex="0"
        aria-label="Navigate to Virtual Assistant"
        [ngClass]="{ 'selected-item': checkCurrentLink('cd') }"
      >
        <a [href]="virtualEnvUrl" target="_blank" rel="noopener noreferrer">
          <svg-icon
            [svgStyle]="{ 'width.%': 78 }"
            id="virtual-assistant-icon"
            src="/assets/images/virtual-assistant.svg"
            title="Customer Assistant"
          >
          </svg-icon>
        </a>
      </div>
    </div>
  </ng-container>
</div>
