import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmptyComponent } from './empty/empty.component';
import { HomeComponent } from './home/home.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { Authority } from './shared/constants/authority.constants';

export const HOME_ROUTES: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: {
      authorities: [Authority.ADMIN, Authority.AGENT]
      // preload: true
    }
  }
];

//For invalid routes Page-Not-Found-Component will display 404 Error page
export const ERROR_ROUTES: Routes = [{ path: '**', component: PageNotFoundComponent }];

@NgModule({
  imports: [RouterModule.forRoot([...HOME_ROUTES, ...ERROR_ROUTES], { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
