<div class="prompt-box__background-layer">
  <div class="prompt-box__modal">
    <div class="prompt-box__icon-container text-center" *ngIf="iconSource">
      <svg-icon [src]="iconSource | assetUrl" [svgStyle]="{ 'width.rem': 5 }"></svg-icon>
    </div>
    <div class="prompt-box__text text-center">
      <h1>{{ promptTitle }}</h1>
      <p>{{ promptDesc }}</p>
      <p *ngIf="countdownRequired">
        <span class="text-danger font-weight-bold">{{ countdownSeconds }}</span> seconds remaining
      </p>
    </div>
    <div class="d-flex mt-4" [ngClass]="okBtnText ? 'justify-content-between' : 'justify-content-center'">
      <button *ngIf="okBtnText" (click)="okBtnFunction()" nbButton status="success" size="large" class="prompt-box__btn">
        {{ okBtnText }}
      </button>
      <button (click)="cancelBtnFunction()" nbButton status="primary" size="large" class="prompt-box__btn">
        {{ cancelBtnText }}
      </button>
    </div>
  </div>
</div>
