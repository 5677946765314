import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-prompt-box',
  templateUrl: './prompt-box.component.html',
  styleUrls: ['./prompt-box.component.scss']
})
export class PromptBoxComponent implements OnInit {
  /**
   * GENERIC PROMPT BOX:
   * OK Button Text and Function can be passed.
   * Cancel Button Text and Function can be passed.
   * If countdown required, which will automatically
   * invoke the function after the given seconds.
   */
  @Input() iconSource?: string = '';
  @Input() promptTitle: string = '';
  @Input() promptDesc?: string = '';
  @Input() okBtnText?: string = '';
  @Input() cancelBtnText?: string = '';
  @Input() okBtnFunction?: () => void;
  @Input() cancelBtnFunction?: () => void;
  @Input() countdownRequired?: boolean = false;
  @Input() countdownOnButton?: 'OK' | 'Cancel' = 'Cancel';
  @Input() countdownSeconds: number = 30;

  private timerId: any;

  ngOnInit(): void {
    if (this.countdownRequired) this.timerId = setInterval(this.countdown, 1000);
  }

  private countdown = () => {
    if (this.countdownSeconds == -1) {
      clearTimeout(this.timerId);
      if (this.countdownOnButton == 'OK') this.okBtnFunction();
      else this.cancelBtnFunction();
    } else {
      this.countdownSeconds--;
    }
  };
}
