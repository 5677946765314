<!-- //

 © 2020 Infosys Limited, Bangalore, India. All Rights Reserved.

 Infosys believes the information in this document is accurate as of its publication date; such
 information is subject to change without notice. Infosys acknowledges the proprietary rights of
 other companies to the trademarks, product names and such other intellectual property rights
 mentioned in this document. Except as expressly permitted, neither this documentation nor any
 part of it may be reproduced, stored in a retrieval system, or transmitted in any form or by any
 means, electronic, mechanical, printing, photocopying, recording or otherwise, without the prior
 permission of Infosys Limited and/or any named intellectual property rights holders under this
 document.

// -->

<div class="footer">
  <div class="d-block d-md-flex py-3">
    <div class="d-flex justify-content-between item-container">
      <div class="d-flex align-items-center flex-wrap mb-2 mb-md-0 w-auto justify-content-md-between first-block">
        <span class="footer-menu-item py-1 py-md-0 w-100"><a>Copyright 2021</a></span>
        <span class="horizontal-separator d-block d-md-none mb-2"></span>
        <span class="separator d-none d-md-block"></span>

        <span class="footer-menu-item py-1 py-md-0 w-100"><a>Privacy</a></span>
        <span class="separator d-none d-md-block"></span>

        <span class="footer-menu-item py-1 py-md-0 w-100"><a>Sitemap</a></span>
        <span class="separator d-none d-md-block"></span>

        <span class="footer-menu-item py-1 py-md-0 w-100"><a>Disclaimer</a></span>
        <span class="horizontal-separator d-block d-md-none mt-2"></span>
      </div>

      <div class="d-flex" *ngIf="checkCurrentLink('agent-desktop')">
        <span class="footer-menu-item py-1 py-md-0 w-100" *ngIf="showDomainSelection">
          <a (click)="changeDomain('telecom')" href="#/agent-desktop/{{ selectedChannel }}/telecom">Telecom</a>
        </span>
        <span class="separator d-none d-md-block" *ngIf="showDomainSelection"></span>
        <span class="footer-menu-item py-1 py-md-0 w-100" *ngIf="showDomainSelection">
          <a (click)="changeDomain('healthcare')" href="#/agent-desktop/{{ selectedChannel }}/healthcare">HealthCare</a>
        </span>
        <span class="d-block d-md-none mt-2"></span>
      </div>
    </div>
    <div class="w-auto ml-0 ml-md-auto">
      <span class="footer-menu-item mr-2">All rights reserved. Powered By Infosys</span>
    </div>
  </div>
</div>
