<div class="nav">
  <div class="d-flex font align-items-center justify-content-between h-100" role="navigation" aria-roledescription="Navigation Bar">
    <div class="d-flex align-items-center">
      <a class="navbar-brand logo my-2" id="main-logo" routerLink="/" aria-label="Infosys Cortex">
        <span
          class="logo-img"
          href="#/"
          [ngStyle]="{
            backgroundImage:
              headerConfig && headerConfig.icon && headerConfig.icon.src && 'url(' + (headerConfig.icon.src | assetUrl) + ')',
            width: headerConfig && headerConfig.icon && headerConfig.icon.width,
            height: headerConfig && headerConfig.icon && headerConfig.icon.height
          }"
        ></span>
      </a>
    </div>

    <div class="icon-bar d-flex align-items-right mr-3 w-100">
      <div class="dropdown d-flex align-items-center">
        <button class="btn dropdown-toggle pr-2">
          <div *ngIf="!mobileMode" class="row p-0 m-0 d-none d-md-flex">
            <div class="col p-0 m-0 navigation-text-color d-flex align-items-center">
              {{ userName }}
            </div>
            <div class="col p-0 m-0 d-flex align-items-center">
              <svg-icon src="/assets/chevron-down.svg" class="d-flex align-items-center" aria-hidden="true"> </svg-icon>
            </div>
          </div>
          <div *ngIf="mobileMode" class="row p-0 m-0 d-flex flex-nowrap d-md-none" aria-label="User">
            <div class="col p-0 m-0" aria-hidden="true">
              <svg-icon src="/assets/user-icon.svg" class="d-flex align-items-center"></svg-icon>
            </div>
            <div class="col p-0 m-0" aria-hidden="true">
              <svg-icon src="/assets/chevron-down.svg" class="d-flex align-items-center"></svg-icon>
            </div>
          </div>
        </button>
      </div>

      <a *ngIf="homeLink" [href]="homeLink">
        <nb-icon class="home-icon" role="button" icon="home-outline"></nb-icon>
      </a>

      <div ngbDropdown class="dropdown d-flex align-items-center">
        <button
          class="btn lang dropdown-toggle"
          id="language-icon"
          aria-label="Change language"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          ngbDropdownToggle
        >
          <svg-icon src="/assets/globe.svg" class="d-flex" aria-hidden="true"></svg-icon>
        </button>
        <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="language-icon">
          <div class="dropdown-item" (click)="changeLanguage('en')">English</div>
          <div class="dropdown-item" (click)="changeLanguage('fr')">French</div>
        </div>
      </div>

      <div class="dropdown d-flex align-items-center" ngbDropdown>
        <button
          class="btn theme dropdown-toggle"
          id="theme-icon"
          aria-label="Change theme"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          ngbDropdownToggle
        >
          <i class="fa fa-paint-brush" aria-hidden="true"></i>
        </button>
        <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="theme-icon">
          <div class="dropdown-item" (click)="changeTheme('light')">Light</div>
          <div class="dropdown-item" (click)="changeTheme('dark')">Dark</div>
          <div class="dropdown-item" (click)="changeTheme('cosmic')">Cosmic</div>
          <div class="dropdown-item" (click)="changeTheme('corporate')">Corporate</div>
          <div class="dropdown-item" (click)="changeTheme('custom-blue')">Custom Blue</div>
        </div>
      </div>

      <button type="button" class="btn bell" id="notification-icon" aria-haspopup="true" id="notify" aria-label="Notification">
        <svg-icon src="/assets/notification-icon-1.svg" class="d-flex align-items-center"></svg-icon>
      </button>

      <div class="sign-out d-md-flex" aria-label="Sign out" tabindex="0" (keyup.enter)="logout()" id="logout" (click)="logout()">
        <svg-icon src="/assets/logout-icon.svg" aria-hidden="true" class="d-flex align-items-center"></svg-icon>
      </div>
    </div>
  </div>
</div>
