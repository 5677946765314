import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserProfile, StateService } from '@cortex/utilities';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  userProfile: UserProfile;
  name: string;
  message = '';
  menuItems = [];
  constructor(private stateService: StateService) {
    this.userProfile = window.cortexCore.auth.userProfile;
  }
  ngOnDestroy(): void {
    localStorage.clear();
  }

  ngOnInit(): void {
    this.name = this.userProfile.name;
    this.stateService.getValueOf('microApp').subscribe(val => {
      val ? (this.menuItems = val) : (this.message = "User doesn't have access to any module. Please contact administrator for access");
    });
  }
  getMenuItem(group: string): void {
    const groupArray = ['Other'];
    groupArray.push(group);
    this.stateService.setKeyValue('getGroup', groupArray);
  }

  checkUserAccessForOptimize() {
    // This needed to be updated as this should come from config
    return window?.cortexCore?.auth?._userProfile?.canAccess.includes("WMD");
  }

  logout(): void {
    window.cortexCore.auth.logout();
  }
}
