import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { IMPRESSIONEVENTS } from "@cortex/telemetry";
import { StateService } from "@cortex/utilities";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { IframeDesktop } from "../../../../agent-desktop/src/app/models/iframe-desktop.model";
import { ShellConfig, SidebarConfig } from "../shell-config.model";
import { AgentDesktopConfig } from "../../../../agent-desktop/src/app/models/agent-desktop.model";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent implements OnInit, OnDestroy {
  @Input() dialer = false;
  @Output() dialerEvent = new EventEmitter();
  group = [];
  cortexLearnLinks = ["track-progress", "learn/courses", "training-bank", "conversational-analytics", "trainer-dashboard"];
  currentRoute;
  private unsubscribeAll$: Subject<void>;
  iframeDesktopList: Array<IframeDesktop> = null;
  activeIframeDesktops: boolean[];
  menuConfig: SidebarConfig = {
    nudgeConfiguration: true,
    aiAsCustomer: true,
    conversationDesigner: true
  };
  softPhoneMode: string;

  constructor(public router: Router, private stateService: StateService) {
    const {
      shellConfig: { sidebar = null } = {},
      "agent-desktop": { iframeDesktops = null } = {}
    }: { shellConfig: ShellConfig; "agent-desktop": AgentDesktopConfig } = window.cortexCore.config.getConfig();

    sidebar && (this.menuConfig = sidebar);

    if (iframeDesktops && iframeDesktops.length) {
      this.iframeDesktopList = iframeDesktops;
      this.activeIframeDesktops = new Array(iframeDesktops.length).fill(false);
    }

    this.softPhoneMode = window.cortexCore?.auth?.userProfile?.ctiProvider ? window.cortexCore?.auth?.userProfile?.ctiProvider : "twilio";
  }

  ngOnInit(): void {
    const currentUrl = this.router.url;
    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
    this.unsubscribeAll$ = new Subject();
    this.stateService
      .getValueOf("getGroup")
      .pipe(takeUntil(this.unsubscribeAll$))
      .subscribe(val => {
        if (val) {
          this.group = val;
        } else {
          this.getgroup();
        }
      });
  }

  checkCurrentIframeDesktop(index: number): boolean {
    return this.activeIframeDesktops[index];
  }

  activateIframeDesktop = (index: number): void => {
    this.activeIframeDesktops.fill(false);
    this.activeIframeDesktops[index] = true;
    this.dispatchIframeDesktopEvent();
  };

  deactivateAllIframeDesktops() {
    if (this.isAnyIframeDesktopActive()) {
      this.activeIframeDesktops.fill(false);
      this.dispatchIframeDesktopEvent();
    }
  }

  private dispatchIframeDesktopEvent = () => {
    window.dispatchEvent(
      new CustomEvent("icortex::iframe-desktop", {
        detail: {
          activeIframeDesktops: this.activeIframeDesktops
        }
      })
    );
  };

  private isAnyIframeDesktopActive(): boolean {
    return this.activeIframeDesktops && this.activeIframeDesktops.some(e => !!e);
  }

  checkUserAccessForOptimize() {
    return window?.cortexCore?.auth?._userProfile?.canAccess.includes("WMD");
  }

  getgroup(): void {
    const microApps = window.cortexCore.config.getConfig()["micro-apps"];
    this.router.events.pipe(takeUntil(this.unsubscribeAll$)).subscribe(event => {
      this.group = ["Other"];
      for (const key in microApps) {
        if (this.router.url.includes(`/${microApps[key].route}`) || microApps[key].route.includes(this.router.url.replace("/", ""))) {
          this.group.push(microApps[key].group);
        }
      }
      for (const cortexLearnLink of this.cortexLearnLinks) {
        if (this.router.url.includes(cortexLearnLink)) {
          this.group.push("Learn");
        }
      }
    });
  }

  checkCurrentLink(str: string, exclude?: string): boolean {
    if (exclude) {
      return this.router.url.includes(str) && !this.isAnyIframeDesktopActive() && !this.router.url.includes(exclude);
    }
    return this.router.url.includes(str) && !this.isAnyIframeDesktopActive();
  }

  get envUrl(): string {
    const cdUrl = window.cortexCore.config.getConfig()["conversationDesignerConfig"]["endpointURL"];
    return `${cdUrl}`;
  }

  get virtualEnvUrl(): string {
    const vaUrl = window.cortexCore.config.getConfig()["virtualAssistantConfig"]["endpointURL"];
    return `${vaUrl}`;
  }

  openDialer(): void {
    this.dialerEvent.emit(!this.dialer);
    this.dialer = !this.dialer;
  }

  sendTelemetry(): void {
    window.cortexCore?.telemetry.impression({
      type: IMPRESSIONEVENTS.VIEW,
      pageid: "Cortex Shell",
      uri: ""
    });
  }
  ngOnDestroy(): void {
    this.unsubscribeAll$.next();
    this.unsubscribeAll$.complete();
  }
}
